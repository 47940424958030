import mithai from '../../assets/mithai.jpg';
 import nasta from "../../assets/nasta.jpg";
 import snacks from '../../assets/snacks.jpg';
 import fastingfood from '../../assets/fastingfood.jpg';
 import drink from '../../assets/drink.jpg';
 import maincorse from '../../assets/maincorse.jpg';


 const data = [{
     name:"Mithai Masti",
     image:mithai
 },
 {
    name:"Nashta",
    image:nasta
},
{
    name:"Chaat and Snacks",
    image:snacks
},
{
    name:"Fasting Food",
    image:fastingfood
},
{
    name:"Drinks",
    image:drink
},{
    name:"Main Course",
    image:maincorse
}



 ];

 export default data; 